import * as React from 'react'

import { Box } from '@mui/material'

import { AppContainer, SeoHeaders } from 'shared/components'

import { PurchaseForm } from '../purchase_form'

import type { RouteComponentProps } from '@reach/router'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Purchase = (props: RouteComponentProps) => (
  <React.Fragment>
    <SeoHeaders title='Comprar' />
    <Box sx={{ maxWidth: 'sm', mx: 'auto' }}>
      <AppContainer sx={{ p: 3 }}>
        <PurchaseForm />
      </AppContainer>
    </Box>
  </React.Fragment>
)

export default Purchase
