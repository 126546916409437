import * as React from 'react'

import { useQuery } from '@apollo/client'
import { CreditCard, SwapCalls, Upload } from '@mui/icons-material'
import { Box, List, ListItem, ListItemText } from '@mui/material'

import { DatedList, DatedListItem, UserNotificationContent } from '../components'
import {
  USER_NOTIFICATIONS_QUERY,
  translateUserNotificationStatus,
  translateUserNotificationType,
} from '../queries'

import type {
  UserNotification,
  UserNotificationType,
  UserNotificationsData,
  UserNotificationsVars,
} from '../queries'

const asTimestamp = (dateStr: string) => new Date(dateStr).getTime()

const userNotificationIcon = (notificationType: UserNotificationType) => {
  switch (notificationType) {
  case 'PURCHASE': return <CreditCard />
  case 'SWAP': return <SwapCalls />
  case 'WITHDRAW_BANK': return <Upload />
  }
}

type UserNotificationDisplayProps = {
  notification: UserNotification
}

const UserNotificationDisplay = ({
  notification,
}: UserNotificationDisplayProps) => (
  <DatedListItem
    icon={userNotificationIcon(notification.notificationType)}
    text={translateUserNotificationType(notification.notificationType)}
    timestamp={asTimestamp(notification.createdAt)}
  >
    <ListItemText
      primary={<UserNotificationContent notification={notification} />}
      primaryTypographyProps={{ textAlign: 'right' }}
      secondary={translateUserNotificationStatus(notification.resolvedAt)}
      secondaryTypographyProps={{ textAlign: 'right' }}
    />
  </DatedListItem>
)

const EmptyNotificationsDisplay = () => (
  <List>
    <ListItem>
      <ListItemText
        primary='No hay notificaciones para mostrar'
        primaryTypographyProps={{ color: 'text.secondary', textAlign: 'center' }}
      />
    </ListItem>
  </List>
)

export const NotificationsView = () => {
  const { loading, data } =
    useQuery<UserNotificationsData, UserNotificationsVars>(USER_NOTIFICATIONS_QUERY)

  const userNotifications = data?.userNotifications || []

  return (
    <Box sx={{ maxWidth: 'md', mx: 'auto' }}>
      <DatedList
        emptyListDisplay={<EmptyNotificationsDisplay />}
        loading={loading}
        items={userNotifications.map((notification) => ({
          timestamp: asTimestamp(notification.createdAt),
          component: <UserNotificationDisplay notification={notification} />,
        }))}
      />
    </Box>
  )
}
