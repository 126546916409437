import * as React from 'react'

import { Box } from '@mui/material'

import { AppContainer, SeoHeaders } from 'shared/components'

import { WithdrawBankForm } from '../withdraw_bank_form'

import type { RouteComponentProps } from '@reach/router'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const WithdrawBank = (props: RouteComponentProps) => (
  <React.Fragment>
    <SeoHeaders title='Retirar a cuenta bancaria' />
    <Box sx={{ maxWidth: 'sm', mx: 'auto' }}>
      <AppContainer sx={{ p: 3 }}>
        <WithdrawBankForm />
      </AppContainer>
    </Box>
  </React.Fragment>
)

export default WithdrawBank
