import * as React from 'react'

import { SeoHeaders } from 'shared/components'
import { NotificationsView } from 'shared/views'

import type { RouteComponentProps } from '@reach/router'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Notifications = (props: RouteComponentProps) => (
  <React.Fragment>
    <SeoHeaders title='Notificaciones' />
    <NotificationsView />
  </React.Fragment>
)

export default Notifications
